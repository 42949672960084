export const getBagDataQueryKey = (
  userId?: MaybeRefOrGetter<string | undefined>
) => ['cart-bag', userId]

export const getItemsCountQueryKey = (
  userId?: MaybeRefOrGetter<string | undefined>
) => ['cart-items-count', userId]

export const getCartQueryKey = (
  cartToken?: MaybeRefOrGetter<string | undefined>
) => ['cart', cartToken]
